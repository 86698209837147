import React from "react";
import { useXarrow } from "react-xarrows";
import LineTo from "react-lineto";

import { Box, Grid } from "@mui/material";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";

import mob_step1 from "../../../Componant/Common/images/User/newmobuser1.png";
import mob_step2 from "../../../Componant/Common/images/User/newmobuser2.png";
import mob_step3 from "../../../Componant/Common/images/User/newmobuser3.png";
import mob_step4 from "../../../Componant/Common/images/User/newmobuser4.png";
import mob_step5 from "../../../Componant/Common/images/User/newmobuser5.png";
import mob_step6 from "../../../Componant/Common/images/User/newmobuser6.png";
import mob_step7 from "../../../Componant/Common/images/User/newmobuser7.png";
import mob_step8 from "../../../Componant/Common/images/User/newmobuser8.png";
import step1_img from "../../../Componant/Common/images/User/newUser1.png";
import step2_img from "../../../Componant/Common/images/User/newUser2.png";
import step3_img from "../../../Componant/Common/images/User/newUser3.png";
import step4_img from "../../../Componant/Common/images/User/newUser4.png";
import step5_img from "../../../Componant/Common/images/User/newUser5.png";
import step6_img from "../../../Componant/Common/images/User/newUser6.png";
import step7_img from "../../../Componant/Common/images/User/newUser7.png";
import step8_img from "../../../Componant/Common/images/User/newUser8.png";

import "./User.scss";
import "../../../Componant/Common/MediaQuery/MediaQuery.scss";
import { useTranslation } from "react-i18next";

const User = () => {
  const { t } = useTranslation();
  return (
    <Box onLoad={useXarrow()} className="landing_contain">
      <Box className="back-shape">
        <Grid container className="grid-row">
          <Grid item sm={8} xs={12} className="leftSide">
            <Box className="dec-img">
              <img src={step1_img} alt="img" className="imgSize" />
            </Box>
            <Box className="mob-img">
              <img src={mob_step1} alt="img" className="imgSize" />
            </Box>

            <Box className="B LineDot" id="dot2"></Box>
            <LineTo from="A" to="B" className="Line-to-draw" />

            <Box className="C LineDot" id="dot3"></Box>
            <LineTo from="B" to="C" className="Line-to-draw" />
          </Grid>
          <Grid item sm={4} xs={12} className="rightSide timeline">
            <Box className="A LineDot" id="dot1"></Box>

            <Box className="mob_step">
              <Box className="1 LineDot" id="Mobdot1"></Box>
              <Box className="2 LineDot" id="Mobdot2"></Box>
              <LineTo from="1" to="2" className="Line-to-draw" />

              <Typography variant="h6" gutterBottom className="round">
                step-1
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step1-title.title")}
              <br />
              <span>
                {t("pages.common.how-it-works.user.step1-title.under-title")}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step1-pera")}
            </Typography>
            <Button
              variant="contained"
              href="https://www.dostkaro.com/"
              className="login_btn"
              id="login-btn"
            >
              login
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className="back-layer">
        <Grid container className="grid-row">
          <Grid item sm={4} xs={12} className="leftside timeline">
            <Box className="D LineDot" id="dot4"></Box>
            <LineTo from="C" to="D" className="Line-to-draw" />

            <Box className="mob_step">
              <Box className="3 LineDot" id="Mobdot3"></Box>
              <LineTo from="2" to="3" className="Line-to-draw" />
              <Box className="4 LineDot" id="Mobdot4"></Box>
              <LineTo from="3" to="4" className="Line-to-draw" />

              <Typography
                variant="h6"
                gutterBottom
                className="round"
                data-aos="zoom-in"
              >
                step-2
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step2-title.title")}
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step2-pera")}
            </Typography>
          </Grid>
          <Grid item sm={8} xs={12} className="rightSide">
            <Box className="dec-img">
              <img src={step2_img} alt="step-img" className="imgSize" />
            </Box>
            <Box className="mob-img">
              <img src={mob_step2} alt="step-img" className="imgSize" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="back-shape2 timechang">
        <Grid container className="grid-row first-row">
          <Grid item sm={6} xs={12} className="leftSide timeline">
            <Box className="E LineDot" id="dot5"></Box>
            <LineTo from="D" to="E" className="Line-to-draw" />
            <Box className="F LineDot" id="dot6"></Box>
            <LineTo from="E" to="F" className="Line-to-draw" />

            <Box className="mob_step">
              <Box className="5 LineDot" id="Mobdot5"></Box>
              <Box className="6 LineDot" id="Mobdot6"></Box>
              <LineTo from="5" to="6" className="Line-to-draw" />

              <Typography
                variant="h6"
                gutterBottom
                className="round"
                data-aos="zoom-in"
                data-aos-duration="500"
              >
                step-3
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step3-title.title")}
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step3-pera")}
            </Typography>
            <Box className="dec-img">
              <img src={step3_img} alt="img" className="imgSize" />
            </Box>
            <Box className="mob-img">
              <img src={mob_step3} alt="img" className="imgSize" />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} className="rightSide timeline">
            <Box className="G LineDot" id="dot7"></Box>
            <LineTo from="F" to="G" className="Line-to-draw" />
            <Box className="H LineDot" id="dot8"></Box>
            <LineTo from="G" to="H" className="Line-to-draw" />

            <Box className="mob_step">
              <Box className="7 LineDot" id="Mobdot7"></Box>
              <Box className="8 LineDot" id="Mobdot8"></Box>
              <LineTo from="7" to="8" className="Line-to-draw" />

              <Typography
                variant="h6"
                gutterBottom
                className="round"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                step-4
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step4-title.title")}
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step4-pera")}
            </Typography>
            <Box className="dec-img step-img">
              <img src={step4_img} alt="img" className="imgSize step4-img" />
            </Box>
            <Box className="mob-img">
              <img src={mob_step4} alt="img" className="imgSize" />
            </Box>
          </Grid>
        </Grid>
        <Grid container className="grid-row second-row">
          <Grid item sm={6} xs={12} className="leftSide timeline">
            <Box className="K LineDot" id="dot11"></Box>
            <LineTo from="J" to="K" className="Line-to-draw" />
            <Box className="L LineDot" id="dot12"></Box>
            <LineTo from="K" to="L" className="Line-to-draw" />

            <Box className="mob_step">
              <Box className="9 LineDot" id="Mobdot9"></Box>
              <Box className="10 LineDot" id="Mobdot10"></Box>
              <LineTo from="9" to="10" className="Line-to-draw" />

              <Typography
                variant="h6"
                gutterBottom
                className="round"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                step-6
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step6-title.title")}
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step6-pera")}
            </Typography>
            <Box className="mob-img">
              <img src={mob_step6} alt="img" className="imgSize" />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} className="rightSide timeline">
            <Box className="I LineDot" id="dot9"></Box>
            <LineTo from="H" to="I" className="Line-to-draw" />
            <Box className="J LineDot" id="dot10"></Box>
            <LineTo from="I" to="J" className="Line-to-draw" />

            <Box className="mob_step">
              <Box className="11 LineDot" id="Mobdot11"></Box>
              <Box className="12 LineDot" id="Mobdot12"></Box>
              <LineTo from="11" to="12" className="Line-to-draw" />

              <Typography
                variant="h6"
                gutterBottom
                className="round"
                data-aos="zoom-in"
                data-aos-duration="500"
              >
                step-5
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step5-title.title")} <br />
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step5-pera")}
            </Typography>
            <Box className="mob-img">
              <img src={mob_step5} alt="img" className="imgSize" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="back-shape3-img">
        <Grid container className="grid-row">
          <Grid item sm={6} xs={12}>
            <Box className="dec-img mx-auto">
              <img src={step6_img} alt="img" className="imgSize" />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box className="dec-img step-img mx-auto">
              <img src={step5_img} alt="img" className="imgSize step5-img" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="back-shape4 timechang ">
        <Grid container className="grid-row">
          <Grid item sm={6} xs={12} className="leftSide timeline">
            <Box className="M LineDot" id="dot13"></Box>
            <LineTo from="L" to="M" className="Line-to-draw" />
            <Box className="N LineDot" id="dot14"></Box>
            <LineTo from="M" to="N" className="Line-to-draw" />

            <Box className="mob_step">
              <Box className="13 LineDot" id="Mobdot13"></Box>
              <Box className="14 LineDot" id="Mobdot14"></Box>
              <LineTo from="13" to="14" className="Line-to-draw" />

              <Typography
                variant="h6"
                gutterBottom
                className="round"
                data-aos="zoom-in"
                data-aos-duration="500"
              >
                step-7
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step7-title.title")} <br />
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step7-pera")}
            </Typography>
            <Box className="dec-img">
              <img src={step7_img} alt="img" className="imgSize" />
            </Box>
            <Box className="mob-img">
              <img src={mob_step7} alt="img" className="imgSize" />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} className="rightSide timeline">
            <Box className="O LineDot" id="dot15"></Box>
            <LineTo from="N" to="O" className="Line-to-draw" />

            <Box className="mob_step">
              <Box className="15 LineDot" id="Mobdot15"></Box>
              <LineTo from="2" to="15" className="Line-to-draw" />

              <Box className="16 LineDot" id="Mobdot16"></Box>
              <LineTo from="15" to="16" className="Line-to-draw" />

              <Typography
                variant="h6"
                gutterBottom
                className="round"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                step-8
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom className="working-title">
              {t("pages.common.how-it-works.user.step8-title.title")}
            </Typography>
            <Typography variant="body2" gutterBottom paragraph>
              {t("pages.common.how-it-works.user.step8-pera")}
            </Typography>
            <Box className="dec-img step-img step8-img">
              <img src={step8_img} alt="img" className="imgSize" />
            </Box>
            <Box className="mob-img">
              <img src={mob_step8} alt="img" className="imgSize" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default User;
