const Terms_Pera_1 =
  "Welcome to Dost, India's Most Advanced Property Search Platform. These Terms and Conditions (the 'Agreement') apply to all users of Dost, including those who are browsing, listing, renting or buying property, or finding a flatmate. Please read this Agreement carefully before accessing or using Dost. By accessing or using any part of Dost, you agree to be bound by the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this Agreement, then you may not access Dost.";
const DEFI_1 =
  "'Agreement' means these Terms and Conditions, including any amendments that may be made from time to time.";
const DEFI_2 = "'Dost' means India's Most Advanced Property Search Platform.";
const DEFI_3 =
  "'Listing' means a property, flatmate or any other item listed on Dost.";
const DEFI_4 = "'User' means any person who accesses or uses Dost";

const Use_Dost_title = "Use of Dost";

const Use_Dost_List = {
  A: "Dost allows users to Rent and Buy properties on their own terms and brokerage-free. Users can also find a Flatmate by paying a nominal fee.",
  B: "Dost reserves the right to refuse service, terminate accounts, remove or edit content, or cancel listings at any time in its sole discretion.",
  C: "Users are solely responsible for the content they post on Dost. By posting a listing, you warrant that you have the right to post the content and that the content is accurate, legal, and not in violation of any third-party rights.",
};

const Fees_title = "Fees";
const Fees = {
  A: "Users can list a property or flatmate for free. However, if they wish to find a flatmate, they will be charged a nominal fee.",
  B: "Dost reserves the right to change its fee structure at any time. Any changes will be communicated to users through Dost.",
};

const User_Conduct_title = "User Conduct";
const User_Conduct = {
  A: "Users are prohibited from using Dost for any unlawful purpose or in any way that violates this Agreement.",
  B: "Users must not post any content that is offensive, defamatory, fraudulent, or infringes on any intellectual property rights.",
  C: "Users must not use Dost to engage in any spamming, hacking, or other malicious activities.",
};

const Limitation_Liability_title = "Limitation of Liability";
const Limitation_Liability = {
  A: "Dost is not responsible for any direct, indirect, incidental, special, or consequential damages arising out of or in any way related to this Agreement or the use of Dost.",
  B: "Dost is not responsible for the accuracy or completeness of any listings posted on Dost. Users should verify all information before entering into any transaction.",
};

const Indemnification_title = "Indemnification";
const Indemnification = {
  A: "Users agree to indemnify and hold Dost harmless from any claims, damages, liabilities, costs, and expenses, including reasonable attorneys' fees, arising out of or in any way related to the user's use of Dost or violation of this Agreement.",
};

const Governing_title = "Governing Law";
const Governing = {
  A: "This Agreement shall be governed by and construed in accordance with the laws of India.",
  B: "Any dispute arising out of or in connection with this Agreement shall be submitted to the exclusive jurisdiction of the courts of India.",
};

const Modifications_Agreement_title = "Modifications to Agreement";
const Modifications_Agreement = {
  A: "Dost reserves the right to modify this Agreement at any time without prior notice.",
  B: "Any changes to this Agreement will be posted on Dost and will be effective immediately upon posting.",
};

const Termination_title = "Termination";
const Termination = {
  A: "Dost may terminate this Agreement at any time without prior notice.",
  B: "Users may terminate this Agreement by ceasing to use Dost.",
};

const Term_Last_Pera =
  "By using Dost, you agree to be bound by this Agreement. If you do not agree to this Agreement, please do not use Dost.";
export {
  Terms_Pera_1,
  DEFI_1,
  DEFI_2,
  DEFI_3,
  DEFI_4,
  Use_Dost_title,
  Use_Dost_List,
  Fees_title,
  Fees,
  User_Conduct_title,
  User_Conduct,
  Limitation_Liability_title,
  Limitation_Liability,
  Indemnification_title,
  Indemnification,
  Governing_title,
  Governing,
  Modifications_Agreement_title,
  Modifications_Agreement,
  Termination_title,
  Termination,
  Term_Last_Pera
};
