const PERA_1 =
  "This Privacy Policy ('Policy') describes the types of information that Dost, India's Most Advanced Property Search Platform, collects from users and how we use that information. By accessing or using Dost, you agree to be bound by this Policy. If you do not agree with the terms of this Policy, please do not access or use Dost.";

const PRIVACY_DATA = [
  {
    id: 1,
    title: "Information Collected",
    peraA:
      "We collect information that users provide when they create an account or list a property or flatmate. This information may include the user's name, email address, phone number, and location.",
    peraB:
      "We may also collect information about the user's device, IP address, and browser type.",
    peraC:
      "We may use cookies to collect information about how users interact with Dost.",
  },
  {
    id: 2,
    title: "Use of Information",
    peraA:
      "We use the information we collect to provide and improve Dost, including customer service, and to personalise the user experience.",
    peraB:
      "We may use the user's email address to send them promotional and marketing material related to Dost.",
    peraC:
      "We may share user information with third-party service providers to help us operate and improve Dost, such as payment processing, data storage, and analytics.",
  },
];

const PRIVACY_TWO = [
  {
    id: 1,
    title: "Security",
    peraA:
      "We take reasonable measures to protect the information we collect from unauthorised access or disclosure.",
    peraB:
      "However, we cannot guarantee that the information will be completely secure, and users provide information at their own risk.",
  },
  {
    id: 2,
    title: "Retention of Information",
    peraA:
      "We retain user information for as long as necessary to provide Dost and for other legitimate business purposes, such as record-keeping, compliance with legal obligations, and resolving disputes.",
    peraB:
      "When user information is no longer necessary, we will delete or anonymize it.",
  },
];

const Third_Party_title = "Third-Party Links";
const Third_Part_A =
  "Dost may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites.";

const PRIVACY_THREE = [
  {
    id: 1,
    title: "Children's Privacy",
    peraA: "Dost is not intended for use by children under the age of 18.",
    peraB:
      "We do not knowingly collect personal information from children under the age of 18. If we become aware that we have collected personal information from a child under the age of 18, we will take steps to delete the information as soon as possible.",
  },
  {
    id: 2,
    title: "Changes to this Policy",
    peraA:
      "We reserve the right to modify this Policy at any time without prior notice.",
    peraB:
      "Any changes to this Policy will be posted on Dost and will be effective immediately upon posting.",
  },
];

const Contact_title = "Contact Us";
const Contact_A =
  "If you have any questions about this Policy or how we use your information, please contact us at [insert contact information].";

const Last_Pera =
  "By accessing or using Dost, you agree to the terms of this Policy. If you do not agree to the terms of this Policy, please do not access or use Dost.";

export {
  PRIVACY_DATA,
  PERA_1,
  PRIVACY_TWO,
  Third_Party_title,
  Third_Part_A,
  PRIVACY_THREE,
  Contact_title,
  Contact_A,
  Last_Pera,
};
