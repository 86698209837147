import React, { useState } from "react";
import { useRef } from "react";
import { useXarrow } from "react-xarrows";
import { StraightLine } from "react-drawline";
import { AnimationOnScroll } from "react-animation-on-scroll";

import { Button, Typography } from "@mui/material";
import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";

import Blue_logo from "../../../Componant/Common/images/createprofile/blue-logo.png";
import curveBG from "../../../Componant/Common/images/HomePage/curveBG.png";
import Footer from "../../../Componant/Common/Footer/Footer";
import location from "../../../Componant/Common/images/HomePage/location.png";
import logo1 from "../../../Componant/Common/images/HomePage/logo1.png";
import logo2 from "../../../Componant/Common/images/HomePage/logo2.png";
import logo3 from "../../../Componant/Common/images/HomePage/logo3.png";
import logo4 from "../../../Componant/Common/images/HomePage/logo4.png";
import logo5 from "../../../Componant/Common/images/HomePage/logo5.png";
import logo6 from "../../../Componant/Common/images/HomePage/logo6.png";
import logo7 from "../../../Componant/Common/images/HomePage/logo7.png";
import logo8 from "../../../Componant/Common/images/HomePage/logo8.png";
import mobcurveBG from "../../../Componant/Common/images/HomePage/mobcurveBG.png";
import mobslideimg2 from "../../../Componant/Common/images/HomePage/mobslideimg2.png";
import sideimg2 from "../../../Componant/Common/images/HomePage/sideimg2.png";

import "./KeyFeatures.scss";
import "../../../Componant/Common/MediaQuery/MediaQuery.scss";
import {
  NEWSLATTER_DATA,
  NEWSLATTER_TITLE,
  SAMPLE_DATA_1,
  SAMPLE_DATA_2,
  SAMPLE_DATA_3,
  SAMPLE_DATA_4,
  SAMPLE_DATA_5,
  SAMPLE_DATA_6,
  SAMPLE_DATA_7,
  SAMPLE_DATA_8,
  TITLE_1,
  TITLE_2,
  TITLE_3,
  TITLE_4,
  TITLE_5,
  TITLE_6,
  TITLE_7,
  TITLE_8,
} from "./constants";

const HomePage = () => {
  const [swBtn, setSwBtn] = useState(true);
  const changeval = (val) => {
    setSwBtn(val);
  };

  const test1 = useRef(null);
  const test2 = useRef(null);
  const test3 = useRef(null);
  const test4 = useRef(null);
  const test5 = useRef(null);
  const test6 = useRef(null);
  const test7 = useRef(null);
  const test8 = useRef(null);

  return (
    <Box className="home_page" onLoad={useXarrow()}>
      <Box className="banner_section">
        <Container className="top-header">
          <Grid container className="header-row">
            <Grid item xs={6}>
              <Box className="brand-logo">
                <a href="/">
                  <img src={Blue_logo} alt="img" className="imgSize" />
                </a>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {/* <Box className="header-switch">
                <Box className="right-switch">
                  <Box className="switches-container">
                    <input
                      type="radio"
                      id="switchUser"
                      name="switchPlan"
                      defaultChecked={swBtn === true ? "checked" : ""}
                      onClick={() => changeval(true)}
                    />
                    <input
                      type="radio"
                      id="switchOwner"
                      name="switchPlan"
                      defaultChecked={swBtn === false ? "checked" : ""}
                      onClick={() => changeval(false)}
                    />
                    <label htmlFor="switchUser">Rent</label>
                    <label htmlFor="switchOwner">Buy</label>
                    <div className="switch-wrapper">
                      <div className="switch">
                        <div>Rent</div>
                        <div>Buy</div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Box> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <section className="top-curve pb-5">
        <Box className="desk-curve curveImg">
          <img src={curveBG} alt="bgimg" className="imgSize" />
        </Box>
        <Box className="mob-curve curveImg">
          <img src={mobcurveBG} alt="img" className="imgSize" />
        </Box>
        <Container>
          <Typography
            variant="h1"
            className="feature-head pt-3 pt-lg-4 pt-xl-5"
          >
            Why you should Rent with Us?
          </Typography>
          <Grid container className="fe-row fe-row1">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <div className="fet-contain">
                <h1>{TITLE_1}</h1>
                <p>{SAMPLE_DATA_1}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <div
                  ref={test1}
                  className="pointer pointerleft"
                  id="test1"
                ></div>
                <Box className="pointer pointerleft" id="test1"></Box>

                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo animate_animated animate__bounceIn">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo1} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>
          <Grid container className="fe-row fe-row2">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <div className="fet-contain">
                <h1>{TITLE_2}</h1>
                <p>{SAMPLE_DATA_2}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <Box className="pointer pointerright" id="test2"></Box>
                <div
                  ref={test2}
                  className="pointer pointerright"
                  id="test2"
                ></div>

                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo2} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>

          <Box className="side-img"></Box>

          <Grid container className="fe-row fe-row1 chang-effact1">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <div className="fet-contain">
                <h1>{TITLE_3}</h1>
                <p>{SAMPLE_DATA_3}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <div
                  ref={test3}
                  className="pointer pointerleft"
                  id="test3"
                ></div>
                <Box className="pointer pointerleft" id="test3"></Box>
                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo3} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>

          <Grid container className="fe-row fe-row2 chang-effact2">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <div className="fet-contain">
                <h1>{TITLE_4}</h1>
                <p>{SAMPLE_DATA_4}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <Box className="pointer pointerright" id="test4"></Box>
                <div
                  ref={test4}
                  className="pointer pointerright"
                  id="test4"
                ></div>
                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo animate_animated animate__bounceIn">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo4} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>

          <div className="mob-side-img"></div>

          <Grid container className="fe-row fe-row1 chang-effact3">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <Box className="sideimg2">
                <img src={sideimg2} alt="sideimg" className="imgSize" />
              </Box>
              <div className="fet-contain">
                <h1>{TITLE_5}</h1>
                <p>{SAMPLE_DATA_5}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <div
                  ref={test5}
                  className="pointer pointerleft"
                  id="test5"
                ></div>
                <Box className="pointer pointerleft" id="test5"></Box>
                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo animate_animated animate__bounceIn">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo5} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>
          <Grid container className="fe-row fe-row2 chang-effact4">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <div className="fet-contain">
                <h1>{TITLE_6}</h1>
                <p>{SAMPLE_DATA_6}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <div
                  ref={test6}
                  className="pointer pointerright"
                  id="test6"
                ></div>
                <Box className="pointer pointerright" id="test6"></Box>
                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo animate_animated animate__bounceIn">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo6} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>
          <Box className="mobsideimg2">
            <img src={mobslideimg2} alt="sideimg" className="imgSize" />
          </Box>
          <Grid container className="fe-row fe-row1 chang-effact5">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <div className="fet-contain">
                <h1>{TITLE_7}</h1>
                <p>{SAMPLE_DATA_7}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <div
                  ref={test7}
                  className="pointer pointerleft"
                  id="test7"
                ></div>
                <Box className="pointer pointerleft" id="test7"></Box>
                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo animate_animated animate__bounceIn">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo7} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>
          <Grid container className="fe-row fe-row2 chang-effact6">
            <Grid item lg={6} md={6} sm={6} className="left-column">
              <div className="fet-contain">
                <h1>{TITLE_8}</h1>
                <p>{SAMPLE_DATA_8}</p>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right-column">
              <div className="main-circle">
                <div className="loc-img">
                  <img src={location} alt="img" className="imgSize" />
                </div>
                <div
                  ref={test8}
                  className="pointer pointerright"
                  id="test8"
                ></div>
                <Box className="pointer pointerright" id="test8"></Box>
                <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce={true}
                >
                  <Box className="main-logo animate_animated animate__bounceIn">
                    <Box className="under-logo">
                      <Box className="logo">
                        <Box className="logo-img">
                          <img src={logo8} alt="img" className="imgSize" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AnimationOnScroll>
              </div>
            </Grid>
          </Grid>
          <Box className="newslatter-box">
            <Typography variant="h4">{NEWSLATTER_TITLE}</Typography>
            <Typography variant="body1">{NEWSLATTER_DATA}</Typography>
            {/* <div className="mail-box">
              <Box className="buttonIn">
                <input
                  type="email"
                  placeholder="enter your Email here"
                  className="in"
                />
                <Button className="in-btn" href="#0">
                  send
                </Button>
              </Box>
            </div> */}
            <Button className="white_btn join-btn" href="https://www.dostkaro.com/">join our community</Button>
          </Box>
        </Container>
      </section>

      <StraightLine
        startingElement={{
          ref: test1,
          x: "center",
          y: "mid",
        }}
        endingElement={{
          ref: test2,
          x: "center",
          y: "top",
        }}
        className="straightline"
      />
      <StraightLine
        startingElement={{
          ref: test2,
          x: "center",
          y: "mid",
        }}
        endingElement={{
          ref: test3,
          x: "center",
          y: "top",
        }}
        className="straightline"
      />
      <StraightLine
        startingElement={{
          ref: test3,
          x: "center",
          y: "mid",
        }}
        endingElement={{
          ref: test4,
          x: "center",
          y: "top",
        }}
        className="straightline"
      />
      <StraightLine
        startingElement={{
          ref: test4,
          x: "center",
          y: "mid",
        }}
        endingElement={{
          ref: test5,
          x: "center",
          y: "top",
        }}
        className="straightline"
      />
      <StraightLine
        startingElement={{
          ref: test5,
          x: "center",
          y: "mid",
        }}
        endingElement={{
          ref: test6,
          x: "center",
          y: "top",
        }}
        className="straightline"
      />
      <StraightLine
        startingElement={{
          ref: test6,
          x: "center",
          y: "mid",
        }}
        endingElement={{
          ref: test7,
          x: "center",
          y: "top",
        }}
        className="straightline"
      />
      <StraightLine
        startingElement={{
          ref: test7,
          x: "center",
          y: "mid",
        }}
        endingElement={{
          ref: test8,
          x: "center",
          y: "top",
        }}
        className="straightline"
      />
      <Footer faq="FAQ" />
    </Box>
  );
};

export default HomePage;
