import { BrowserRouter, Route, Routes } from "react-router-dom";

import AOS from "aos";
import $ from "jquery";

import KeyFeatures from "../pages/common/KeyFeatures/KeyFeatures";
import HowitWorks from "../pages/common/HowitWorks/HowitWorks";
import PrivacyPolicy from "../pages/common/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../pages/common/TermsAndCondition/TermsAndCondition";

$(function () {
  AOS.init({ offset: 100, duration: 700, easing: "ease-out-quad", once: true });
  window.addEventListener("load", AOS.refresh);
});

export default function Routing() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<KeyFeatures />} />
          <Route path="/howitWorks" element={<HowitWorks />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndCondition" element={<TermsAndCondition />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}
