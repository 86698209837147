export const Keylines = [
  {
    id: 1,
    start: "test1",
    end: "test2",
    color: "#00A9F5",
    path: "smooth",
    curveness: 0,
    strokeWidth: 3,
    showHead: false,
    dashness: true,
  },
  {
    id: 2,
    start: "test2",
    end: "test3",
    color: "#00A9F5",
    path: "smooth",
    curveness: 0,
    strokeWidth: 3,
    showHead: false,
    dashness: true,
  },
  {
    id: 3,
    start: "test3",
    end: "test4",
    color: "#00A9F5",
    path: "smooth",
    curveness: 0,
    strokeWidth: 3,
    showHead: false,
    dashness: true,
  },
  {
    id: 4,
    start: "test4",
    end: "test5",
    color: "#00A9F5",
    path: "smooth",
    curveness: 0,
    strokeWidth: 3,
    showHead: false,
    dashness: true,
  },
  {
    id: 5,
    start: "test5",
    end: "test6",
    color: "#00A9F5",
    path: "smooth",
    curveness: 0,
    strokeWidth: 3,
    showHead: false,
    dashness: true,
  },
  {
    id: 6,
    start: "test6",
    end: "test7",
    color: "#00A9F5",
    path: "smooth",
    curveness: 0,
    strokeWidth: 3,
    showHead: false,
    dashness: true,
  },
  {
    id: 7,
    start: "test7",
    end: "test8",
    color: "#00A9F5",
    path: "smooth",
    curveness: 0,
    strokeWidth: 3,
    showHead: false,
    dashness: true,
  },
];

export const Keylines2 = [
  {
    id: 1,
    startRef: "test1",
    endRef: "test2",
  },
  {
    id: 2,
    startRef: "test2",
    endRef: "test3",
  },
  {
    id: 3,
    startRef: "test3",
    endRef: "test4",
  },
  {
    id: 4,
    startRef: "test4",
    endRef: "test5",
  },
  {
    id: 5,
    startRef: "test5",
    endRef: "test6",
  },
  {
    id: 6,
    startRef: "test6",
    endRef: "test7",
  },
  {
    id: 7,
    startRef: "test7",
    endRef: "test8",
  },
];

const TITLE_1 = "No Brokerage";
const TITLE_2 = "Deposit Free Homes";
const TITLE_3 = "Rent Property On Your Own Terms";
const TITLE_4 = "Property Verification By Experts";
const TITLE_5 = "Find Flatmates";
const TITLE_6 = "Online Rental Agreement And Police Verification";
const TITLE_7 = "Save Money And Earn Rewards";
const TITLE_8 = "Quick, Easy And Hassle Free Property Search";

const SAMPLE_DATA_1 =
  "Dost is India's most advanced property search platform that helps customers find rental homes without paying any brokerage fee.Dost has a vast database of verified property listings that are updated regularly to ensure that customers get access to their dream homes brokerage free.";

const SAMPLE_DATA_2 =
  "Dost allows potential renters to rent homes without paying heavy deposit fees in one go by paying a nominal non-refundable fee.";

const SAMPLE_DATA_3 =
  "Dost uses advanced technology and data-driven algorithms to provide a hassle-free and convenient rental home search experience in which potential renters find homes on their own preferences";

const SAMPLE_DATA_4 =
  "To avoid fake and false listings Dost has state of the art property verification process which uses advanced QR technology. To provide potential renters with actual images and details only and is verified by our property experts.";

const SAMPLE_DATA_5 =
  "Dost's advanced technology algorithm also enables you to find a flatmate based on your preferences and interests as we want you to find DOST and not just a flatmate.";

const SAMPLE_DATA_6 =
  "At Dost we are using our Aadhar Verification process that allows users to get Rental Agreement and Police Verification process online without any additional fees.";

const SAMPLE_DATA_7 =
  "Dost also allows you to earn rewards and benefits while paying rent through us. Dost Karo Aish Karo.";

const SAMPLE_DATA_8 =
  "Dost database is built bypartnering directly with the property owners across India. By doing so, Dost has a comprehensive listing of properties available for rent in various cities across the country.Using Dost, customers can search for properties based on their preferences. The platform offers a range of search filters that enable customers to narrow down their search results and find properties that best suit their requirements. Once customers have shortlisted their preferred properties, they can schedule a site visit directly with the property owner or manager through the Dost platform.";

const NEWSLATTER_TITLE = "Join Our Community. DOST KARO";

const NEWSLATTER_DATA =
  "Learn all things Real Estate and Property investment and become a member of DOSTKARO Community.";

export {
  TITLE_1,
  TITLE_2,
  TITLE_3,
  TITLE_4,
  TITLE_5,
  TITLE_6,
  TITLE_7,
  TITLE_8,
  SAMPLE_DATA_1,
  SAMPLE_DATA_2,
  SAMPLE_DATA_3,
  SAMPLE_DATA_4,
  SAMPLE_DATA_5,
  SAMPLE_DATA_6,
  SAMPLE_DATA_7,
  SAMPLE_DATA_8,
  NEWSLATTER_TITLE,
  NEWSLATTER_DATA,
};
