import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./PrivacyPolicy.scss";

import { Button, Typography } from "@mui/material";
import { Box, Container, Grid } from "@mui/material";

import LOGO from "../../../Componant/Common/images/createprofile/white-logo.png";
import Footer from "../../../Componant/Common/Footer/Footer";
import {
  PERA_1,
  PRIVACY_TWO,
  PRIVACY_DATA,
  PRIVACY_THREE,
  Third_Part_A,
  Third_Party_title,
  Contact_title,
  Contact_A,
  Last_Pera,
} from "./constants";

export default function PrivacyPolicy() {
  const renderPrivacy = PRIVACY_DATA.map((data) => {
    const { id, title, peraA, peraB, peraC } = data;
    return (
      <li className="main-list-item" key={id}>
        <Typography className="title">{title}</Typography>
        <ol type="A">
          <li>{peraA}</li>
          <li>{peraB}</li>
          <li>{peraC}</li>
        </ol>
      </li>
    );
  });

  const renderPrivacyTwo = PRIVACY_TWO.map((data) => {
    const { id, title, peraA, peraB } = data;
    return (
      <li className="main-list-item" key={id}>
        <Typography className="title">{title}</Typography>
        <ol type="A">
          <li>{peraA}</li>
          <li>{peraB}</li>
        </ol>
      </li>
    );
  });
  
  const renderPrivacyThree = PRIVACY_THREE.map((data) => {
    const { id, title, peraA, peraB } = data;
    return (
      <li className="main-list-item" key={id}>
        <Typography className="title">{title}</Typography>
        <ol type="A">
          <li>{peraA}</li>
          <li>{peraB}</li>
        </ol>
      </li>
    );
  });
  return (
    <Box id="BlogDetails">
      <Box className="blog-homesection">
        <Box className="home-contain">
          <Container>
            <Box className="top-header">
              <Box className="brand-logo">
                <Link to="/">
                  <img src={LOGO} alt="logo" className="imgSize" />
                </Link>
              </Box>
            </Box>
            <Grid container>
              <Grid item xs={12} className="single-part" data-aos="fade-up">
                <h1>Privacy Policy </h1>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box id="main-contant">
        <Container>
          <Typography className="pera">{PERA_1}</Typography>

          <ul className="main-list">
            {renderPrivacy}
            {renderPrivacyTwo}
            <li className="main-list-item">
              <Typography className="title">{Third_Party_title}</Typography>
              <ol type="A">
                <li>{Third_Part_A}</li>
              </ol>
            </li>
            {renderPrivacyThree}
            <li className="main-list-item">
              <Typography className="title">{Contact_title}</Typography>
              <ol type="A">
                <li>{Contact_A}</li>
              </ol>
            </li>
          </ul>

          <Typography className="pera" style={{marginTop:"4rem"}}>{Last_Pera}</Typography>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
}
