import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";

import { Box, Container, Grid } from "@mui/material";

import footerLogo from "../images/HomePage/footerLogo.png";
import appstore from "../images/HomePage/appstore.png";
import playstore from "../images/HomePage/playstore.png";

import "./Footer.scss";

const Footer = (props) => {
  return (
    <div className="footer-section">
      <Container className="foot-container">
        <Grid container className="foot-row">
          <Grid item lg={2} md={2} sm={2} xs={12}>
            <Box className="foot-logo">
              <Link to="/">
                <img src={footerLogo} alt="logo" className="imgSize" />
              </Link>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <ul>
              <li>
                <a href="/howitWorks">How It Works</a>
              </li>
              <li>
                <a href="https://www.dostkaro.com/">Support</a>
              </li>
              <li>
                <a href="/#">FAQ</a>
              </li>
            </ul>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <ul>
              <li>
                <a href="https://blog.dostkaro.in/">blogs</a>
              </li>
              <li>
                <a href="/PrivacyPolicy">Privacy Policy</a>
              </li>
              <li>
                <a href="/TermsAndCondition">Terms & Conditions </a>
              </li>
            </ul>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} className="last-col">
            <Box className="Apps-img">
              <Box className="app-img">
                <a href="#0">
                  <img src={appstore} alt="appstore" className="imgSize" />
                </a>
              </Box>
              <Box className="app-img">
                <a href="#0">
                  <img src={playstore} alt="gpay" className="imgSize" />
                </a>
              </Box>
            </Box>
            <ul className="foot-icons">
              <li>
                <a href="https://instagram.com/dost_officials">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=100086128652877&mibextid=LQQJ4d">
                  <BsFacebook />
                </a>
              </li>

            </ul>
          </Grid>
        </Grid>
        <div className="fot-copyright">
          <h4>All Copyrights Reserved</h4>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
