import React, { useState } from "react";
import "../../../Componant/Common/MediaQuery/MediaQuery.scss";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";

import $ from "jquery";
import homemen from "../../../Componant/Common/images/User/home-men.png";
import LOGO from "../../../Componant/Common/images/User/LOGO.png";
import Owner from "../../OwnerSide/Owner/Owner";
import User from "../../UserSide/User/User";

import "../../../Componant/Common/ToggleSwitch/ToggleSwitch.css";
import "./HowitWorks.scss";

const useStyles = makeStyles((theme) => ({
  select: {
    borderRadius: "0px 0px 18px 18px !important",
    border: "none !important",
    color: "#fff !important",
    fontWeight: "500 !important",
    top: "36px !important",
    boxShadow:
      "0px 5px 5px -3px rgb(0 0 0 / 7%), 0px 8px 7px 1px rgb(0 0 0 / 6%),0px 3px 8px 2px rgb(0 0 0 / -1%) !important",
    "& ul": {
      textTransform: "capitalize",
      backgroundColor: "#fff",
      color: "#3ac2ff",
      padding: "0 !important",
      fontWeight: 600,
      textAlign: "center",
    },
  },
}));

export default function HowitWorks() {
  const [swBtn, setSwBtn] = useState(true);
  const [open, setOpen] = useState(false);

  /**changeval: changes value for switch button */
  const changeval = (val) => {
    setSwBtn(val);
  };

  const classes = useStyles();

  /**handleChange: handle changes for switch button */
  const handleChange = (event) => {
    setSwBtn(event.target.value);
    $(function () {
      event.target.value === true
        ? $("#switchUser").click()
        : $("#switchOwner").click();
    });
  };

  /**handleModalOpen: toggles modal open and close*/
  const handleModalOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="bg_color">
      <div className="home_section">
        <Grid container className="top_header">
          <Grid item xs={6}>
            <Box className="brand-logo" onClick={() => changeval(true)}>
              <Link to="/">
                <img src={LOGO} alt="" className="imgSize" />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6} className="right-header">
            <div className="right-switch">
              <div className="switches-container">
                <input
                  type="radio"
                  id="switchUser"
                  name="switchPlan"
                  defaultChecked={swBtn === true ? "checked" : ""}
                  onClick={() => changeval(true)}
                />
                <input
                  type="radio"
                  id="switchOwner"
                  name="switchPlan"
                  defaultChecked={swBtn === false ? "checked" : ""}
                  onClick={() => changeval(false)}
                />
                <label htmlFor="switchUser">User</label>
                <label htmlFor="switchOwner">Owner</label>
                <div className="switch-wrapper">
                  <div className="switch">
                    <div>User</div>
                    <div>Owner</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mob-right-switch">
              <FormControl className="from-control">
                <Select
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={handleModalOpen}
                  onOpen={handleModalOpen}
                  onChange={handleChange}
                  value={swBtn}
                  MenuProps={{
                    classes: { paper: classes.select },
                  }}
                >
                  <MenuItem
                    className="menu-item-style"
                    style={{ display: swBtn ? "none" : "block" }}
                    value={true}
                  >
                    user
                  </MenuItem>
                  <MenuItem
                    className="menu-item-style"
                    style={{ display: swBtn ? "block" : "none" }}
                    value={false}
                  >
                    owner
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <Container>
          <Grid container className="grid_row">
            <Grid item lg={6} md={6} sm={6}>
              <Box className="home-men">
                <img src={homemen} alt="mam-img" className="imgSize" />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="right">
              <div className="journey-txt">
                <div>
                  <h1 className="start">start</h1>
                  <h1 className="your">your</h1>
                </div>
                <h1 className="journey">journey!</h1>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {swBtn === true ? <User /> : <Owner />}
    </div>
  );
}
