import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../PrivacyPolicy/PrivacyPolicy.scss";

import { Button, Typography } from "@mui/material";
import { Box, Container, Grid } from "@mui/material";

import LOGO from "../../../Componant/Common/images/createprofile/white-logo.png";
import Footer from "../../../Componant/Common/Footer/Footer";
import {
  Contact_title,
  Contact_A,
  Last_Pera,
  Terms_Pera_1,
  DEFI_1,
  DEFI_2,
  DEFI_3,
  DEFI_4,
  Use_Dost_title,
  Use_Dost_List,
  Fees_title,
  Fees,
  User_Conduct,
  User_Conduct_title,
  Limitation_Liability,
  Limitation_Liability_title,
  Indemnification,
  Indemnification_title,
  Governing,
  Governing_title,
  Modifications_Agreement_title,
  Modifications_Agreement,
  Termination,
  Term_Last_Pera,
  Termination_title,
} from "./constants";

export default function TermsAndCondition() {
  return (
    <Box id="BlogDetails">
      <Box className="blog-homesection">
        <Box className="home-contain">
          <Container>
            <Box className="top-header">
              <Box className="brand-logo">
                <Link to="/">
                  <img src={LOGO} alt="logo" className="imgSize" />
                </Link>
              </Box>
            </Box>
            <Grid container>
              <Grid item xs={12} className="single-part" data-aos="fade-up">
                <h1>Terms and Conditions</h1>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box id="main-contant">
        <Container>
          <Typography className="pera">{Terms_Pera_1}</Typography>
          <Typography className="defination-title">Definitions</Typography>
          <ul>
            <li>{DEFI_1}</li>
            <li>{DEFI_2}</li>
            <li>{DEFI_3}</li>
            <li>{DEFI_4}</li>
          </ul>
          <ul className="main-list">
            <li className="main-list-item">
              <Typography className="title">{Use_Dost_title}</Typography>
              <ol type="A">
                <li>{Use_Dost_List.A}</li>
                <li>{Use_Dost_List.B}</li>
                <li>{Use_Dost_List.C}</li>
              </ol>
            </li>
            <li className="main-list-item">
              <Typography className="title">{Fees_title}</Typography>
              <ol type="A">
                <li>{Fees.A}</li>
                <li>{Fees.B}</li>
              </ol>
            </li>
            <li className="main-list-item">
              <Typography className="title">{User_Conduct_title}</Typography>
              <ol type="A">
                <li>{User_Conduct.A}</li>
                <li>{User_Conduct.B}</li>
                <li>{User_Conduct.C}</li>
              </ol>
            </li>
            <li className="main-list-item">
              <Typography className="title">{Limitation_Liability_title}</Typography>
              <ol type="A">
                <li>{Limitation_Liability.A}</li>
                <li>{Limitation_Liability.B}</li>
              </ol>
            </li>
            <li className="main-list-item">
              <Typography className="title">{Indemnification_title}</Typography>
              <ol type="A">
                <li>{Indemnification.A}</li>
              </ol>
            </li>

            <li className="main-list-item">
              <Typography className="title">{Governing_title}</Typography>
              <ol type="A">
                <li>{Governing.A}</li>
                <li>{Governing.B}</li>
              </ol>
            </li>

            <li className="main-list-item">
              <Typography className="title">{Modifications_Agreement_title}</Typography>
              <ol type="A">
                <li>{Modifications_Agreement.A}</li>
                <li>{Modifications_Agreement.B}</li>
              </ol>
            </li>

            <li className="main-list-item">
              <Typography className="title">{Termination_title}</Typography>
              <ol type="A">
                <li>{Termination.A}</li>
                <li>{Termination.B}</li>
              </ol>
            </li>
          </ul>

          <Typography className="pera" style={{ marginTop: "4rem" }}>
            {Term_Last_Pera}
          </Typography>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
}
